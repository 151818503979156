.boldLetter {
  font-weight: bold;
}

.nb {
  margin-bottom: 0rem;
  color: red;
}
.profileImage {
  max-width: 150px;
}
.profileLabel {
  font-weight: bold;
}

.profilePic {
  position: relative;
  display: inline-block;
}

.editProfilePic {
  position: relative;
  display: inline-block;
}

.info-tooltip {
  font-size: 0.8rem;
}

.imageAlign {
  text-align: center;
}
#inputImageUpload {
  display: none;
}
.editImage {
  cursor: pointer;
  font-size: 1.5em;
  color: #24b845;
}
