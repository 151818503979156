.accordion .card-header .icon-location {
  color: #24b845;
}
.close-bullet,
.open-bullet {
  position: relative;
}
.close-bullet::before,
.open-bullet::before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  left: -9px;
  top: 17px;
  border-radius: 100%;
  background: #ccc;
}
.open-bullet::before {
  background: #24b845;
}
.hour-right {
  float: right;
  font-size: 14px;
}
.hour-right b {
  color: #24b845;
  padding-left: 5px;
}
