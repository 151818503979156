.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
}

.login-sliderImage {
  background: url(../../assets/images/login-screen-2.jpg) center center
    no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
}

.icon-verified_user,
.icon-account_circle {
  color: black;
}

.form-align-middle {
  margin-top: 10%;
}
.login-screen {
  padding-top: 70px;
}
