.chart-wrapper {
    /* height: 750px;
    width: 500px; */
    margin: auto;
}
.card-body1 {
    height: auto;
}

.card-header1{
    background-color: #41B883!important; 
    color: #fff;
}

.card-header2{
    background-color: #E46651!important; 
    color: #fff;
}

.card-header3{
    background-color: #00D8FF!important; 
    color: #fff;
}

.card-title {
   font-weight: 600;
}
.card-header h5 a {
    color: #24b845;
}