.img-fluid {
  max-width: 200px!important;
 }
/* .card-header {
  font-weight: bold;
}
.statusShow {
  color: #24b845;
}
.form-control.inlineSpace {
  display: inline-block;
  width: 75px;
  margin: 0 10px;
}
.dataTables_length label,
.dataTables_filter label {
  white-space: nowrap;
}
.form-control.search-input {
  width: 120px;
}

.spinner {
  width: 100%;
  height: 100%;
}

.spinner > div {
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* .cancel-button{
  color: white;
  background-color: red;
}
.submit-button{
  color: white;
  background-color: green;
  position: absolute;
  left: 365px;
  top: 170px;
} */
