.spinner {
  width: 100%;
  height: 100%;
}

.spinner > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel-button {
  color: white;
  background-color: red;
}
.submit-button {
  color: white;
  background-color: green;
  position: absolute;
  left: 365px;
  top: 170px;
}

.switch {
  position: relative;
  display: inline-block;
  /* width: 60px; */
  /* height: 27px; */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0a280a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 10px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #24b845;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.profile-ul {
  display: block;
}
.profile-ul li {
  display: inline-block;
  padding: 5px 30px;
  font-size: 16px;
  font-weight: 500;
  color: #0a280a;
}
.profile-ul li span {
  color: #24b845;
  font-size: 20px;
}
.grid-ul {
  float: right;
}
.grid-ul li {
  display: inline-block;
}
.grid-ul li a {
  display: block;
  padding: 10px;
  /* background: ; */
}
.detail-box {
  display: block;
}
.detail-box h4 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: #0a280a;
}
.detail-box p {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  line-height: 25px;
}
.right-detailbox {
  display: block;
}
.right-top-section {
  display: block;
}
.right-top-section table {
  display: block;
}
.right-top-section table tr td {
  width: 50%;
  padding: 5px 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.right-top-section table tr td:first-child {
  font-size: 14px;
  color: #49c465;
  font-weight: 500;
  padding-left: 0;
}
.right-top-section table tr td .rupees-symbol {
  font-weight: 500;
  color: #49c465;
}
.checked {
  color: #040404;
}
.review-nochecked {
  color: #ffd200;
}
.location-ico {
  display: block;
  padding: 20px 10px 10px 0px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 10px;
}
.location-ico .icon-location {
  font-size: 20px;
  color: #24b845;
}
.icon-location{
  display: flex;
  padding: 1%;
}
.open-today {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #24b845;
  font-size: 13px;
}
.open-today p {
  color: #040404;
  padding-top: 8px;
}
.edit-service,
.edit-service a {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #24b845;
  font-size: 13px;
  text-transform: uppercase;
}
.col-md-12.grid {
  flex: 0 0 auto;
  width: 33.3333333333%;
  float: left;
  padding-right: calc(var(--bs-gutter-x) / 3);
  padding-left: calc(var(--bs-gutter-x) / 3);
  border: none;
  background: none;
}
.grid .full-width {
  flex: 0 0 auto;
  width: 100%;
}
.grid .full-width .table-responsive tbody tr {
  width: 50%;
  display: inline-block;
}
.grid .full-width .table-responsive tbody {
  display: block;
}
.active {
  color: #24b845;
}
